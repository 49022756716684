import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "src/layout/layout"
import PageWrapper from "src/components/page-wrapper"
import SEO from "src/components/seo"
import CardVertical from "../components/card-vertical"
import Grid from "@material-ui/core/Grid"
import { Typography } from "@material-ui/core"
import { Link } from "@material-ui/core"
import content from "src/locales/en/contact.json"

const useStyles = makeStyles({
  root: {
    width: "85%",
    margin: "0 auto",
    paddingTop: "84px",
  },
  rootContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "36px",
  },
  mainContentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    marginBottom: "48px",
    "@media (max-width:768px)": {
      width: "100%",
      marginTop: "24px",
    },
  },
  links: {
    textDecoration: "underline",
  },
  header: {
    display: "inline-block",
  },
  headerContainer: {
    marginBottom: "24px",
  },
  sectionContainer: {
    margin: "24px 0",
  },
  locationsContainer: {
    marginTop: "48px",
  },
  gridContainer: {
    marginTop: "24px",
    marginBottom: "48px",
    "@media (max-width:768px)": {
      marginTop: "24px",
      marginBottom: "24px",
    },
  },
})

export default function ContactPage({ pageContext, location }) {
  const data = useStaticQuery(graphql`
    query ContactContent {
      sectionLocationImage1: file(
        relativePath: { eq: "contact/dp_aerogels_sg_location.png" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sectionLocationImage2: file(
        relativePath: { eq: "contact/dp_aerogels_my_location.png" }
      ) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const sectionLocationImage1 = data.sectionLocationImage1.childImageSharp.fluid
  const sectionLocationImage2 = data.sectionLocationImage2.childImageSharp.fluid

  content.sectionLocations.cards[0].imageData = sectionLocationImage1
  content.sectionLocations.cards[1].imageData = sectionLocationImage2

  const classes = useStyles()

  const {
    breadcrumb: { crumbs },
  } = pageContext
  const customCrumbLabel = crumbs[crumbs.length - 1].crumbLabel.replace(
    "-",
    " "
  )

  return (
    <>
      <Layout location={location}>
        <SEO title={content.seo.title} />
        <PageWrapper>
          <div className={classes.root}>
            <Breadcrumb crumbs={crumbs} crumbLabel={customCrumbLabel} />
            <div className={classes.rootContainer}>
              <div className={classes.headerContainer}>
                <Typography className={classes.header} variant="h3">
                  {content.hero.heading}
                </Typography>
              </div>
              <div className={classes.sectionContainer}>
                <Typography className={classes.header} variant="body1">
                  {content.sectionSales.heading}
                </Typography>
                <br />
                <Link
                  href={"mailto:info@dpaerogels.com"}
                  variant="body1"
                  color="primary"
                  className={classes.links}
                >
                  <Typography className={classes.header} variant="body1">
                    {content.sectionSales.description}
                  </Typography>
                </Link>
              </div>
              <div className={classes.sectionContainer}>
                <Typography className={classes.header} variant="body1">
                  {content.sectionPress.heading}
                </Typography>
                <br />
                <Link
                  href={"mailto:press@dpaerogels.com"}
                  variant="body1"
                  color="primary"
                  className={classes.links}
                >
                  <Typography className={classes.header} variant="body1">
                    {content.sectionPress.email}
                  </Typography>
                </Link>
              </div>
              <div className={classes.locationsContainer}>
                <Typography className={classes.header} variant="h3">
                  {content.sectionLocations.heading}
                </Typography>
                <Grid
                  className={classes.gridContainer}
                  container
                  justify="center"
                  direction="row"
                  alignItems="center"
                  spacing={4}
                >
                  {content.sectionLocations.cards.map((card, index) => (
                    <Grid key={index} item xs={12} sm={6}>
                      <CardVertical
                        title={card.heading}
                        description={card.address}
                        imageData={card.imageData}
                        imgAlt={card.imgAlt}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </div>
          </div>
        </PageWrapper>
      </Layout>
    </>
  )
}
